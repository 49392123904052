import Constants from '../../constants';
import React, { useEffect, useContext, useState } from 'react';
import Grid from '@mui/material/Grid';
import { Formik, Form, Field } from 'formik';

//MUI
import MUIDataTable from "mui-datatables";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField, CheckboxWithLabel } from 'formik-mui'
import { DataNumber } from '../FormManager';
import { Card, CardContent, CardHeader, InputAdornment, Typography } from '@mui/material';
const constants = Constants;
const Training = props => {
    const { selectedBaseline, selectedDays, DetailObj, ErrObj } = props
    // console.log('---------------------------------------------------', props);

    const [totalTrainingCostSaved, settotalTrainingCostSaved] = React.useState(0);
    const [HoursOfTrainingErr, setHoursOfTrainingErr] = React.useState(ErrObj.hoursOfTrainingErr);
    const [reqObj, setreqObj] = React.useState(
        {
            hoursOfTraining: 0,
            totalTrainingCostSaved: 0
        }
    );

    useEffect(() => {
        setHoursOfTrainingErr(DetailObj.hoursOfTraining > 0 ? '': 'Required')
    }, [ErrObj]);

    useEffect(() => {
        let data = reqObj
        data.hoursOfTraining = DetailObj.hoursOfTraining,
            data.totalTrainingCostSaved = DetailObj.totalTrainingCostSaved,

            setreqObj({ ...data })
    }, [DetailObj]);

    
    useEffect(() => {
        if (DetailObj.hoursOfTraining > 0) {
            TrainingHandle(DetailObj.hoursOfTraining)
        }
    }, []);

    

    const validate = (values) => {
        // console.log('cjeclllllll', values);
        const errors = {};
        if (values.hoursOfTraining === 0)
            errors.hoursOfTraining = "Must have value";
        if (values.hoursOfTraining < 0)
            errors.hoursOfTraining = "invalid input";
        setHoursOfTrainingErr('')
        // console.log('hendelconsLbPerMsf+++++++++++++++++++++++++++++', errors);
        if (!errors.hoursOfTraining && values.hoursOfTraining > 0) {
            
            TrainingHandle(values.hoursOfTraining)
        } else {
            settotalTrainingCostSaved(0)
        }
        props.updateRequest(values.hoursOfTraining, 'hoursOfTraining')
        return errors;
    }
    const TrainingHandle = (value) => {
        const data = reqObj
            data.hoursOfTraining = value
            data.totalTrainingCostSaved = value * 250
            settotalTrainingCostSaved(value * 250)
            setreqObj({ ...data })
        props.TrainingHandle(reqObj)

    }



    const formatnum = (num) => {
        let value = parseFloat(num)
        let formatted = Math.round(value);
        return parseFloat(formatted).toLocaleString();
    }
    const Tformatnum = (number) => {
      if (isNaN(number) || number === null) {
            return "Invalid Number";
        }
        if (Number.isInteger(number)) {
            return number.toString();
        } else {
            let formattedNumber = Number(number).toFixed(2);
            formattedNumber = formattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return formattedNumber;
        }
    }
    const handleFocus = (e) => {
        e.target.select(); // Selects the input value
    };
    if (selectedBaseline !== null) {
        return <>
            <Grid container xl={6} style={{ padding: '16px', marginTop: '20px' }}>
                <Grid item xl={12}>
                    <Formik initialValues={reqObj} validate={validate} validateOnBlur={true}>
                        <Form>
                            <Card variant="outlined" >
                                <CardHeader title="Training" />
                                <CardContent>
                                    <Grid style={{ paddingLeft: '5px' }}>
                                        <Grid spacing={1} container item xs={12} sx={{ borderTop: 1, borderLeft: 1 }}>
                                            <Grid sx={{ borderBottom: 1, borderRight: 1, paddingRight: 1 }} item xs={4} align="right">
                                                <div>Hours of training</div>
                                            </Grid>
                                            <Grid sx={{ borderBottom: 1, borderRight: 1, paddingRight: 1 }} item xs={4} align="center">
                                                <div>
                                                    $/hour
                                                </div>
                                            </Grid>
                                            <Grid sx={{ borderBottom: 1, borderRight: 1, paddingRight: 1 }} item xs={4} align="center">
                                                <div>
                                                    Total Cost Saved
                                                </div>
                                            </Grid>
                                            <Grid sx={{ borderBottom: 1, borderRight: 1, paddingRight: 1 }} item xs={4} align="right">
                                                {/* <DataNumber handelChange={handelhoursOfTr} noBlurAction={true} name={'hoursOfTraining'} label={'Hours of training'} /> */}
                                                <Field id='trainingsec' onWheel={(e) => e.target.blur()} style={{}} label="Hours of training" component={TextField} name="hoursOfTraining" type="number"
                                                    onFocus={handleFocus} inputProps={{min: 0, step: "any"}} onKeyDown={(e) => {
                                                        if (e.keyCode === 13) {
                                                            e.preventDefault();
                                                        }
                                                        if (e.keyCode === 38 || e.keyCode === 40 || e.keyCode === 189) {
                                                            e.preventDefault();
                                                        }
                                                    }}/>
                                                    {
                                                    HoursOfTrainingErr !== "" ? <Typography align='' style={{ fontSize: '0.75rem' }} color="error">{HoursOfTrainingErr}</Typography> : <></>
                                                }
                                            </Grid>
                                            <Grid sx={{ borderBottom: 1, borderRight: 1, paddingRight: 1 }} item xs={4} align="right">
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <p>$</p>
                                                    <p>250</p>
                                                </div>
                                            </Grid>
                                            <Grid sx={{ borderBottom: 1, borderRight: 1, paddingRight: 1 }} item xs={4} align="right">
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <p>$</p>
                                                    <p>{formatnum(totalTrainingCostSaved)}</p>
                                                </div>
                                            </Grid>


                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Form>
                    </Formik>
                </Grid>
            </Grid>
        </>
    }

};
export default Training;